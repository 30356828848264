<template>
  <div id="chart" style="width: 450px">
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import FirebaseDB from "../../../utils/firebaseDB";

import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
export default {
  name: "ChartListProduits",

  data: function () {
    return {
      series: [
        {
          name: "Produits",
          data: [0],
        },
        {
          name: "Commandes",
          data: [1],
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: "50%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 2,
        },

        grid: {
          row: {
            colors: ["#fff", "#f2f2f2"],
          },
        },
        xaxis: {
          labels: {
            rotate: -45,
          },
          categories: ["Produits", "Commandes"],
          tickPlacement: "on",
        },
        yaxis: {
          title: {
            text: "Quantité",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 0.85,
            opacityTo: 0.85,
            stops: [50, 0, 100],
          },
        },
        title: {
          text: "Quantité des produits et des commandes",
          align: "left",
        },
      },
    };
  },
  methods: {
    async updateChart() {
      var generalStats = await FirebaseDB.getGeneralStats();
      var numberTotalProducts = 0;
      var numberTotalOrders = 0;

      generalStats.forEach((stat) => {
        numberTotalProducts = stat.data().number_total_products;
        numberTotalOrders = stat.data().number_total_orders;
      });

      const products = this.series[0].data.map(() => {
        return numberTotalProducts;
      });

      const orders = this.series[0].data.map(() => {
        return numberTotalOrders;
      });

      // In the same way, update the series option
      this.series = [
        {
          data: products,
        },
        {
          data: orders,
        },
      ];
    },
  },
  beforeMount() {
    this.updateChart();
  },
};
</script>
